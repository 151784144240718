import React, { useEffect, useState } from "react";
import { Modal, message, Button } from "antd";
import Cookies from "js-cookie";
import { format } from "date-fns";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const DocumentVault = () => {
  const [sortBy, setSortBy] = useState("default");
  const [documents, setDocuments] = useState([]);
  const [sorteddocuments, setSortedDocuments] = useState([]);

  const getAllDocument = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/file/geAllDocumentVault`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.ok) {
        const json = await response.json();
        setDocuments(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  useEffect(() => {
    getAllDocument();
  }, []);

  useEffect(() => {
    let sortedDocs = [...documents];

    if (sortBy === "name") {
      sortedDocs.sort((a, b) => a.file_name.localeCompare(b.file_name));
    } else if (sortBy === "type") {
      sortedDocs.sort((a, b) => {
        const getFileExtension = (fileName) => {
          const parts = fileName.split(".");
          return parts.length > 1 ? parts.pop().toLowerCase() : "unknown"; // Handle files with no extension
        };

        return getFileExtension(a.file_name).localeCompare(
          getFileExtension(b.file_name)
        );
      });
    } else {
      sortedDocs.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      ); // Newest first
    }

    setSortedDocuments(sortedDocs);
  }, [documents, sortBy]);

  return (
    <div
      style={{
        height:
          window.innerWidth < 1200
            ? `calc(${window.innerHeight}px - 80px)`
            : "calc(100% - 80px)",
        width: window.innerWidth < 1200 ? "100%" : "calc(100% - 40px)",
        marginLeft: window.innerWidth < 1200 ? "0px" : "20px",
        background:
          window.innerWidth < 1200
            ? "transparent"
            : "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        color: "#142C44CC",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            color: "#142C44CC",
            fontFamily: "Manrope,sans-serif",
            fontWeight: "800",
            fontSize: "18px",
          }}
        >
          {window.innerWidth < 1200 ? "Documents" : "Document Vault"}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: window.innerWidth < 1200 ? "auto" : "250px",
          }}
        >
          <label
            htmlFor="sort-by"
            style={{
              marginRight: "7px",
              marginLeft: "10px",
              color: "#142C44CC",
              fontWeight: "800",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
            }}
          >
            Sort by:
          </label>
          <div style={{ width: "60%" }}>
            <select
              id="sort-by"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              style={{
                width: "100%",
                borderRadius: "30px",
                color: "#0E5AA5",
                backgroundColor: "#ECF0F5",
                border: "1px solid #3E536733",
                fontWeight: "600",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              }}
            >
              <option value="default">Date & Time</option>
              <option value="name">File Name</option>
              <option value="type">File Type</option>
            </select>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          height:
            sorteddocuments && sorteddocuments.length > 0 ? "auto" : "100%",
          maxHeight:
            sorteddocuments && sorteddocuments.length > 0 ? "100%" : "100%",
          width: "100%",
          overflowY: "auto",
        }}
      >
        {sorteddocuments && sorteddocuments.length > 0 ? (
          sorteddocuments.map((doc, index) => (
            <div
              className="border_change_hover"
              style={{
                padding: "20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width:
                  window.innerWidth < 1200
                    ? `${window.innerWidth / 2 - 30}px`
                    : "120px",
                height: "120px",
                textAlign: "center",
                border: "1px solid #1557a93A",
                cursor: "pointer",
              }}
              key={index}
              onClick={(e) => {
                e.preventDefault();
                window.open(doc.url, "_blank");
              }}
            >
              <div>
                <FileCopyIcon
                  style={{ color: "#1557a94A", fontSize: "24px" }}
                />
              </div>
              <div style={{ fontSize: "12px" }}>
                {doc?.file_name?.length < 10
                  ? doc?.file_name
                  : doc?.file_name?.slice(0, 10) + "..." || "Unnamed"}
              </div>
              <div style={{ fontSize: "9px" }}>
                {formatDate(doc.created_at)}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0.5,
            }}
          >
            Document Vault is empty.
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentVault;
