import Login from "../pages/auth/Signin";
import Signup from "../pages/auth/Signup";
import ConfirmPassword from "../pages/auth/ConfirmPassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import VerifyEmail from "../pages/auth/VerifyEmail";
import PhoneNumber from "../pages/auth/PhoneNumber";
import SyncWithSingpass from "../pages/auth/SyncWithSingpass";
import ConfirmEmail from "../pages/auth/ConfirmEmail";
import OTPCode from "../pages/auth/OtpCode";
import ContactUs from "../pages/auth/ContactUs";
import Dashboard from "../scenes/dashboard";
// import Team from "../scenes/team";
import Loan from "../scenes/loan";

// import Dashboardlayout from "../layout/Dashboardlayout";'
import DashboardLayout from "../../src/pages/dashboard/dashboard";
import DashboardBorrowerLayout from "../../src/pages/borrower-dashboard/borrower-dashboard";
import BorrowerLoan from "../../src/borrower/loan/loan";
import BorrowerApplyLoan from "../../src/borrower/loan/apply-loan";
import Messaging from "../../src/borrower/loan/message";
import AdminMessaging from "../../src/borrower/loan/message-admin";
import BorrowerOngoingLoans from "../../src/borrower/loan/ongoing-loans";
import BorrowerRequestedLoans from "../../src/borrower/loan/pending-loans";
import BorrowerAcceptedLoans from "../../src/borrower/loan/accepted-loan";
import BorrowerAgreements from "../../src/borrower/agreements";
import BorrowerTransactions from "../../src/borrower/transactions";
import Layout from "../layout/VerticalLayout";
import LoanRequestGrid from "../scenes/loan-request-grid";
import LoanRequestList from "../scenes/loan-request-list";
import AcceptedLoans from "../scenes/accepted-loans";
import PendingAcceptance from "../scenes/pending-acceptance";
import DelayedPayments from "../scenes/delayed-payments";
import Analytics from "../scenes/analytics";
import Agreements from "../scenes/agreements";
import Subscription from "../scenes/subscription";
import Profile from "../scenes/profile";
import DashboardAdminLayout from "../pages/admin-dashboard/admin-dashboard";
import Users from "../admin/users";
import OngoingAgreement from "../admin/agreement/ongoing-agreement";
import GenerateAgreement from "../admin/agreement/generate-agreement";
import PendingSign from "../admin/agreement/pending-sign";
import LenderPayment from "../admin/payment/lender-payment";
import BorrowerPayment from "../admin/payment/borrower-payment";
import Advertisement from "../admin/advertisement";
import AdobeSign from "../scenes/AdobeSign";
import OfferedLoan from "../admin/loan/offered-loan";
import RequestedLoan from "../admin/loan/request-loan";
import OngoingLoan from "../admin/loan/ongoing-loan";
import AdminLoan from "../admin/loan/admin-loan";
import AdminAnalytic from "../admin/admin-analytics";
import DashboardMobile from "../borrower/borrower_mobile/dashboard_mobile";
import SidebarMobile from "../borrower/global/SidebarMobile";
import UserDatabase from "../admin/user-database";
import RedFlagged from "../admin/loan/red-flagged";
import ProfileStaging from "../borrower/profile staging";
import Home from "../pages/smart-lend-frontend/src/Homepage";
import WhySmartlend from "../pages/smart-lend-frontend/src/WhySmartLend";
import FrequentlyAskedQuestion from "../pages/smart-lend-frontend/src/Faq";
import ContactUsLandingPage from "../pages/smart-lend-frontend/src/ContactUs";
import NewsEvents from "../pages/smart-lend-frontend/src/NewsEvents";
import PartnerWithUs from "../pages/smart-lend-frontend/src/PartnerWithUs";
import NewsAndEvents from "../admin/nande";
import DirectorAuthorization from "../pages/smart-lend-frontend/src/DirectorAuthorization";
import VerifyBorrower from "../admin/verify-borrower";
import Roadmap from "../pages/smart-lend-frontend/src/Roadmap";
import ViewFeedback from "../admin/view-feedback";
import CorporateStructureLoan from "../admin/loan/corporate-structure-loan";
import Valuer from "../admin/property-valuer";
import EmployeeSetup from "../admin/employee";
import MyProfile from "../pages/dashboard/myprofile";
import SuperAdmin from "../pages/smart-lend-frontend/src/SuperAdmin";
import DocumentBuilder from "../scenes/documentBuilder";
import RevampHomepage from "../pages/smart-lend-frontend/src/revamphomepage";
import SolePropStruvtureLoan from "../admin/loan/sole-prop-loan";
import TestCharge from "../admin/loan/test-charge";
import ViewAdminTask from "../admin/admin-task";
import CBSPartner from "../pages/smart-lend-frontend/src/CBSPartner";
import CbsCampagin from "../pages/borrower-dashboard/CbsCampaign";
import TestButton from "../admin/loan/test-button";
import DocumentVault from "../pages/borrower-dashboard/DocumentVault";

// import mapStatetoProps from "../layout/VerticalLayout";
// import Products from "../pages/admin/Products";
// import AddProduct from "../pages/admin/AddProduct";
const authRoutes = [
  { path: "/auth/signin", component: Login },
  { path: "/auth/forgot-password", component: ForgotPassword },
  { path: "/auth/reset-password", component: ResetPassword },
];

const landingPageRoutes = [
  { path: "/home", component: Home },
  { path: "/whysmartlend", component: WhySmartlend },
  { path: "/home", component: Home },
  { path: "/revamp-homepage", component: RevampHomepage },
  { path: "/frequentlyaskedquestions", component: FrequentlyAskedQuestion },
  { path: "/contactus", component: ContactUsLandingPage },
  { path: "/news-events", component: NewsEvents },
  { path: "/cbs-partnership", component: CBSPartner },
  { path: "/partner-with-us", component: PartnerWithUs },
  { path: "/director-authorization", component: DirectorAuthorization },
  { path: "/roadmap", component: Roadmap },
  { path: "/slrysuperadmin", component: SuperAdmin },
];

const verifyRoutes = [
  { path: "/auth/signup", component: Signup },
  { path: "/auth/verify-email", component: VerifyEmail },
  { path: "/auth/confirm-password", component: ConfirmPassword },
  { path: "/auth/phone-number", component: PhoneNumber },
  { path: "/auth/otp-code", component: OTPCode },
  // { path: "/auth/sync-with-singpass", component: SyncWithSingpass },
  { path: "/auth/confirm-email", component: ConfirmEmail },
  { path: "/auth/contact-us", component: ContactUs },
];

const dashboardRoutes = [
  { path: "/dashboard", component: DashboardLayout },
  { path: "/loan-request", component: LoanRequestList },
  { path: "/loan-request-grid", component: LoanRequestGrid },
  { path: "/loan", component: Loan },
  { path: "/accepted-loans", component: AcceptedLoans },
  { path: "/pending-acceptance", component: PendingAcceptance },
  { path: "/l-payments", component: DelayedPayments },
  { path: "/analytics", component: Analytics },
  { path: "/agreements", component: Agreements },
  { path: "/subscription", component: Subscription },
  { path: "/profile", component: Profile },
  { path: "/adobe-sign", component: AdobeSign },
  { path: "/l-messages", component: Messaging },
  { path: "/l-profile", component: MyProfile },
  { path: "/l-document-builder", component: DocumentBuilder },
];
const borrowerDashboardRoutes = [
  { path: "/b-dashboard", component: DashboardBorrowerLayout },
  { path: "/b-loan", component: BorrowerLoan },
  { path: "/b-apply-loan", component: BorrowerApplyLoan },
  { path: "/b-loan-in-progress", component: BorrowerOngoingLoans },
  { path: "/b-requested-loans", component: BorrowerRequestedLoans },
  { path: "/b-accepted-loans", component: BorrowerAcceptedLoans },
  { path: "/b-agreements", component: BorrowerAgreements },
  { path: "/b-transactions", component: BorrowerTransactions },
  { path: "/b-profile", component: ProfileStaging },
  { path: "/stg-b-profile", component: ProfileStaging },
  { path: "/b-messages", component: Messaging },
  { path: "/b-cbs-campaign", component: CbsCampagin },
  { path: "/b-document-vault", component: DocumentVault },
  { path: "/dashboard-mobile", component: DashboardMobile },
  { path: "/sidebar-mobile", component: SidebarMobile },
];

const adminDashboardRoutes = [
  { path: "/a-users", component: Users },
  { path: "/a-ongoing-agreement", component: OngoingAgreement },
  { path: "/a-generate-agreement", component: GenerateAgreement },
  { path: "/a-pending-sign", component: PendingSign },
  { path: "/a-lender-payment", component: LenderPayment },
  { path: "/a-borrower-payment", component: BorrowerPayment },
  { path: "/a-advertisement", component: Advertisement },
  { path: "/a-offered-loan", component: OfferedLoan },
  { path: "/a-requested-loan", component: RequestedLoan },
  { path: "/a-corporate-structure-loan", component: CorporateStructureLoan },
  { path: "/a-sole-prop-loan", component: SolePropStruvtureLoan },
  { path: "/a-ongoing-loan", component: OngoingLoan },
  { path: "/a-loan", component: AdminLoan },
  { path: "/a-analytic", component: AdminAnalytic },
  { path: "/a-user-database", component: UserDatabase },
  { path: "/a-red-flagged", component: RedFlagged },
  { path: "/a-news-events", component: NewsAndEvents },
  { path: "/a-verify-borrower", component: VerifyBorrower },
  { path: "/a-message", component: AdminMessaging },
  { path: "/a-feedback", component: ViewFeedback },
  { path: "/a-admin-task", component: ViewAdminTask },
  { path: "/a-propvalue", component: Valuer },
  { path: "/a-employee", component: EmployeeSetup },
  { path: "/a-manual-giro", component: TestCharge },
  { path: "/a-test-button", component: TestButton },
];

const verticalRoutes = [{ path: "/vertical-dashboard", component: Layout }];

export {
  authRoutes,
  landingPageRoutes,
  verifyRoutes,
  dashboardRoutes,
  verticalRoutes,
  borrowerDashboardRoutes,
  adminDashboardRoutes,
};
