import React, { useEffect, useState } from "react";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import cookies from "js-cookie";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";

const Valuer = () => {
  const [valuerForm, setValuerForm] = useState({
    propertyTypeCode: "hdb",
    postalCode: "",
    addressUnit: "",
    addressNumber: "",
    addressStreet: "",
    projectName: "",
    valuationType: "sale",
  });
  const [retreivedData, setRetreivedData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [postalCodeSearching, setPostalCodeSearching] = useState("");
  const [openTab, setOpenTab] = useState(0);

  const submitValuation = async (postalCode, propertyTypeCode) => {
    setSearching(true);
    try {
      let item = {};
      if (
        valuerForm.propertyTypeCode !== null &&
        valuerForm.propertyTypeCode !== ""
      ) {
        item.propertyTypeCode = valuerForm.propertyTypeCode;
      }

      if (propertyTypeCode !== null && propertyTypeCode !== "") {
        item.propertyTypeCode = propertyTypeCode;
      }

      if (postalCode !== null && postalCode !== "") {
        item.postalCode = postalCode;
      }
      if (valuerForm.addressUnit !== null && valuerForm.addressUnit !== "") {
        item.addressUnit = valuerForm.addressUnit;
      }
      if (
        valuerForm.addressNumber !== null &&
        valuerForm.addressNumber !== ""
      ) {
        item.addressNumber = valuerForm.addressNumber;
      }
      if (
        valuerForm.addressStreet !== null &&
        valuerForm.addressStreet !== ""
      ) {
        item.addressStreet = valuerForm.addressStreet;
      }
      if (valuerForm.projectName !== null && valuerForm.projectName !== "") {
        item.projectName = valuerForm.projectName;
      }
      if (
        valuerForm.valuationType !== null &&
        valuerForm.valuationType !== ""
      ) {
        item.valuationType = valuerForm.valuationType;
      }

      const response = await fetch(
        `https://www.smart-lend.com/api/property/getPropertyValuation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      const json = await response.json();

      if (json.code === 200) {
        console.log("call was succesful");
        message.success("API call was succesful!");
        if (Array.isArray(json.data)) {
          const adjustingData = [...json.data];
          for (const data of adjustingData) {
            data.fullAddress =
              data?.addressUnit +
              " " +
              data?.addressNumber +
              ", " +
              data?.addressStreet +
              ", " +
              data?.postalCode +
              ", " +
              data?.country;
          }
          setRetreivedData(json.data);
        }
        setSearching(false);
      }
    } catch (error) {
      console.error(error);
      setSearching(false);
    }
  };

  const [dataBorrower, setDataBorrower] = useState([]);
  const [searchingArray, setSearchingArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/user/getAllUser",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        setDataBorrower(
          json["Borrower"].filter(
            (bor) =>
              bor?.singpass_data?.person?.cpfhousingwithdrawal
                ?.withdrawaldetails?.length > 0 
              //   &&
              // bor?.singpass_data?.person?.ownerprivate?.value === true
          )
        );

        const searchList = json["Borrower"]
          .filter(
            (bor) =>
              bor?.singpass_data?.person?.cpfhousingwithdrawal
                ?.withdrawaldetails?.length > 0 
                // &&
                // bor?.singpass_data?.person?.ownerprivate?.value === true
          )
          .map((bor, index) => {
            if (
              bor?.singpass_data?.person?.cpfhousingwithdrawal
                ?.withdrawaldetails?.length > 0 
                // &&
                // bor?.singpass_data?.person?.ownerprivate?.value === true
            ) {
              return {
                address:
                  bor?.singpass_data?.person?.cpfhousingwithdrawal
                    ?.withdrawaldetails,
                searched: false,
                possibleResults: [],
                index: index, // Assigning the index here
              };
            }
            return null; // return null if no hdbownership exists
          })
          .filter((item) => item !== null); // Filter out null values

        // Use searchList as needed
        console.log(searchList);
        setSearchingArray(searchList);
        startSeachResults(searchList);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const startSeachResults = async (arr) => {
    let fullSearchData = arr;
    if (fullSearchData?.length > 0) {
      for (const toSearch2 of fullSearchData) {
        // Initialize possibleResults array with empty slots
        let possibleResults = new Array(toSearch2.address.length).fill(null);

        for (let i = 0; i < toSearch2.address.length; i++) {
          const toSearch = toSearch2.address[i];

          try {
            let item = {
              propertyTypeCode: "hdb",
              postalCode: toSearch.address.postal.value,
              addressUnit: ``,
              addressNumber: "",
              addressStreet: "",
              valuationType: "sale",
              projectName: "",
            };

            const response = await fetch(
              `https://www.smart-lend.com/api/property/getPropertyValuation`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(item),
              }
            );
            let item2 = {
              propertyTypeCode: "land",
              postalCode: toSearch.address.postal.value,
              addressUnit: ``,
              addressNumber: "",
              addressStreet: "",
              valuationType: "sale",
              projectName: "",
            };

            const response2 = await fetch(
              `https://www.smart-lend.com/api/property/getPropertyValuation`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(item2),
              }
            );
            let item3 = {
              propertyTypeCode: "condo",
              postalCode: toSearch.address.postal.value,
              addressUnit: ``,
              addressNumber: "",
              addressStreet: "",
              valuationType: "sale",
              projectName: "",
            };

            const response3 = await fetch(
              `https://www.smart-lend.com/api/property/getPropertyValuation`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(item3),
              }
            );
            let item4 = {
              propertyTypeCode: "office",
              postalCode: toSearch.address.postal.value,
              addressUnit: ``,
              addressNumber: "",
              addressStreet: "",
              valuationType: "sale",
              projectName: "",
            };

            const response4 = await fetch(
              `https://www.smart-lend.com/api/property/getPropertyValuation`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(item4),
              }
            );

            const json = await response.json();
            const json2 = await response2.json();
            const json3 = await response3.json();
            const json4 = await response4.json();

            // Find the index of the current search object in fullSearchData
            const searchDataIndex = fullSearchData.findIndex(
              (se) => se.index === toSearch2.index
            );

            if (searchDataIndex > -1) {
              const updatingSearchData = [...fullSearchData];

              // Ensure possibleResults array exists in the search data object
              if (!updatingSearchData[searchDataIndex].possibleResults) {
                updatingSearchData[searchDataIndex].possibleResults = [];
              }

              // Store the result in the correct index
              possibleResults[i] = [
                ...json.data,
                ...json2.data,
                ...json3.data,
                ...json4.data,
              ];

              // Update the searched flag and results
              updatingSearchData[searchDataIndex].searched = true;
              updatingSearchData[searchDataIndex].possibleResults =
                possibleResults;
              // console.log(updatingSearchData);
              setSearchingArray(updatingSearchData);
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  };

  const bestResults = (data) => {
    return data.address.map((addr, index) => {
      const floorUnit = `${addr.address.floor.value}-${addr.address.unit.value}`;

      // Ensure possibleResults exists at the same index
      if (!data.possibleResults || !data.possibleResults[index]) {
        return <div key={index}>{index + 1 + ") "}No results</div>;
      }

      // Filter possibleResults at the same index
      const matchingResults = data.possibleResults[index].filter(
        (result) => result.addressUnit === floorUnit
      );

      // Return formatted string based on number of results
      if (matchingResults.length > 1) {
        return (
          <div key={index}>
            {index + 1 + ") " + `${matchingResults.length} results found`}
          </div>
        );
      } else if (matchingResults.length === 1) {
        // Format saleValuation in SGD
        const formattedValuation =
          matchingResults[0].saleValuation.toLocaleString("en-US", {
            style: "currency",
            currency: "SGD",
          }) +
          " (" +
          matchingResults[0].propertyTypeCode +
          ")";
        return <div key={index}>{index + 1 + ") " + formattedValuation}</div>;
      } else {
        return <div key={index}>{index + 1 + ") "}No results</div>;
      }
    });
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setInputSearch(userInput);

    // Extract the 6-digit postal code
    const postalCodeMatch = userInput.match(/\b\d{6}\b/);
    const postalCode = postalCodeMatch ? postalCodeMatch[0] : null;
    console.log(
      postalCodeMatch,
      postalCode && postalCode !== postalCodeSearching
    );

    if (postalCode && postalCode !== postalCodeSearching) {
      submitValuation(postalCode, valuerForm.propertyTypeCode);
      setPostalCodeSearching(postalCode);
    }
  };

  const cleanedInput = inputSearch
    .replace(postalCodeSearching, "") // Remove postal code from the input
    .replace("Unit", "") // Remove postal code from the input
    .replace("Floor", "") // Remove postal code from the input
    .replace("Block", "") // Remove postal code from the input
    .replace("Blk", "") // Remove postal code from the input
    .replace("unit", "") // Remove postal code from the input
    .replace("floor", "") // Remove postal code from the input
    .replace("block", "") // Remove postal code from the input
    .replace("blk", "") // Remove postal code from the input
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .trim()
    .split(/\s+/);

  const filteredSearch = retreivedData?.filter((address) =>
    cleanedInput.every((term) =>
      address.fullAddress.toLowerCase().includes(term.toLowerCase())
    )
  );

  const capitalizeWords = (str) => {
    if (!str) return ""; // Check if the string exists (handles null or undefined)

    return str
      .split(" ") // Split the string by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join the words back into a string
  };

  const [csvData, setCsvData] = useState([]);

  const sendToPromoCode = async (data) => {
    try {
      const formData = new FormData();
      // Append each promo code individually (simulating an array)
      data.forEach((code) => formData.append("promo_codes[]", code));

      const response = await fetch(
        "https://www.smart-lend.com/api/storePromoCodes",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData,
        }
      );

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result; // Read file content
        const parsedData = parseCsv(text); // Convert CSV to array
        const filteredData = parsedData.map((row) => row[3]);

        // sendToPromoCode(filteredData);
        console.log(filteredData); // Update state with parsed data
      };
      reader.readAsText(file); // Read the file as text
    }
  };

  const parseCsv = (text) => {
    const rows = text.split("\n"); // Split the file into lines
    return rows.map((row) => row.split(",")); // Split each line into an array of values
  };

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          marginBottom: "7px",
        }}
      >
        <div
          style={{
            background: openTab !== 0 ? "transparent" : "#142C44CC",
            color: openTab !== 0 ? "#142C44CC" : "white",
            padding: "7px 20px",
            cursor: "pointer",
            borderRadius: "8px",
          }}
          onClick={() => setOpenTab(0)}
        >
          Search Property
        </div>
        <div
          style={{
            background: openTab !== 1 ? "transparent" : "#142C44CC",
            color: openTab !== 1 ? "#142C44CC" : "white",
            padding: "7px 20px",
            cursor: "pointer",
            borderRadius: "8px",
          }}
          onClick={() => setOpenTab(1)}
        >
          Alpha Regex Search
        </div>

        {/* <input type="file" accept=".csv" onChange={handleFileUpload} /> */}
      </div>
      {openTab === 0 ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "space-between",
            gap: "20px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div style={{ width: "40%" }}>
            <div style={{ width: "100%", marginBottom: "15px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "20px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                  }}
                >
                  Property Valuation
                </div>
              </div>
              <div>This form is specifically for test purposes.</div>
            </div>
            <div style={{ width: "100%" }}>
              <div>General Search:</div>
              <input
                value={inputSearch}
                onChange={(e) => {
                  console.log(valuerForm.propertyTypeCode);
                  handleInputChange(e, valuerForm.propertyTypeCode);
                }}
                placeholder="Search by postal code"
                style={{
                  borderRadius: "8px",
                  border: "1px solid lightgrey",
                  width: "100%",
                  padding: "5px 8px",
                }}
              />
            </div>
            <div style={{ width: "100%", marginTop: "10px" }}>
              <div>Property Type:</div>
              <select
                value={valuerForm.propertyTypeCode}
                onChange={(e) => {
                  setValuerForm({
                    ...valuerForm,
                    propertyTypeCode: e.target.value,
                  });
                  submitValuation(postalCodeSearching, e.target.value);
                }}
                style={{
                  borderRadius: "8px",
                  border: "1px solid lightgrey",
                  width: "100%",
                  padding: "5px 8px",
                }}
              >
                <option value={"hdb"}>HDB</option>
                <option value={"condo"}>Condo</option>
                <option value={"land"}>Landed</option>
                <option value={"office"}>Office</option>
              </select>
            </div>
            {/* 
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Address Unit:</div>
          <input
            value={valuerForm.addressUnit}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, addressUnit: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Address Number:</div>
          <input
            value={valuerForm.addressNumber}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, addressNumber: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Address Street:</div>
          <input
            value={valuerForm.addressStreet}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, addressStreet: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Postal Code:</div>
          <input
            value={valuerForm.postalCode}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, postalCode: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              submitValuation();
            }}
            style={{
              background: "rgb(17, 92, 166)",
              color: "white",
              padding: "7px 30px",
              border: "none",
              marginTop: "10px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            Submit
          </div>
        </div> */}
          </div>
          <div
            style={{
              width: "50%",
              height: "100%",
              overflowX: "hidden",
              overflowY: "auto",
              gap: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%", marginBottom: "15px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "20px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                  }}
                >
                  Search Results
                </div>
              </div>
              <div>Start typing in the search bar to get results here.</div>
            </div>
            {!searching ? (
              filteredSearch && filteredSearch.length > 0 ? (
                filteredSearch.map((addr, index) => (
                  <div
                    key={index}
                    style={{
                      background: "rgba(256,256,256,0.7)",
                      padding: "10px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "18px",
                        color: "#142C44CC",
                      }}
                    >
                      Full Address
                    </div>
                    <div>
                      {"Unit " +
                        addr?.addressUnit +
                        ", BLK " +
                        addr?.addressNumber +
                        ", " +
                        capitalizeWords(addr?.addressStreet) +
                        ", " +
                        addr?.postalCode +
                        ", " +
                        capitalizeWords(addr?.country) +
                        "."}
                    </div>
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#142C44CC",
                        marginTop: "10px",
                      }}
                    >
                      Valuation Details
                    </div>
                    <div style={{ display: "flex", gap: "15px", opacity: 0.4 }}>
                      <div>{addr?.sizeSqft + " Sqft"}</div>
                      <div>
                        {addr?.saleValuationPsf?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        }) + " / Sqft"}
                      </div>
                      <div>
                        {addr?.saleValuation?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: "60%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <SearchOffOutlinedIcon
                    style={{
                      fontSize: "120px",
                      color: "rgba(41, 75, 110, 0.5)",
                    }}
                  />
                  <div
                    style={{
                      color: "rgba(41, 75, 110, 0.5)",
                      fontWeight: 700,
                      fontSize: "24px",
                    }}
                  >
                    No Data Found
                  </div>
                </div>
              )
            ) : (
              <div
                style={{
                  width: "60%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CircularProgress
                  style={{ color: "rgba(41, 75, 110, 0.5)" }}
                  size={150}
                  thickness={5} // Adjust thickness as needed
                />
              </div>
            )}
          </div>
        </div>
      ) : openTab === 1 ? (
        <div style={{ width: "100%", height: "90%" }}>
          <div style={{ width: "100%", marginBottom: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "20px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                }}
              >
                All Borrowers
              </div>
            </div>
            <div>
              This Alpha Regex Expression finds the best suitable property for
              the given address in the borrower's singpass data.
            </div>
          </div>
          <div
            style={{
              background: "rgba(256,256,256,0.4)",
              borderRadius: "8px",
              fontSize: "10px",
              height: "75vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {dataBorrower
              .filter(
                (bor) =>
                  bor?.singpass_data?.person?.cpfhousingwithdrawal
                    ?.withdrawaldetails?.length > 0 
                    // &&
                    // bor?.singpass_data?.person?.ownerprivate?.value === true
              )
              .map((bor, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "8px",
                    background: index % 2 ? "#142C442A" : "transparent",
                  }}
                >
                  <div style={{ width: "12%" }}>{bor?.email}</div>
                  <div style={{ width: "12%" }}>{bor?.principal_name}</div>
                  <div style={{ width: "12%" }}>{bor?.entity_name}</div>
                  <div style={{ width: "12%" }}>
                    {bor?.credit_report_data
                      ? JSON.parse(bor.credit_report_data)
                          ?.NoteworthyOngoingLoans
                        ? JSON.parse(bor.credit_report_data)
                            .NoteworthyOngoingLoans.filter(
                              (tram) =>
                                tram.includes("private residential purchase") ||
                                tram.includes("hdb loan")
                            )
                            .map((imp, index10) => (
                              <div key={index10} style={{}}>
                                <div>
                                  {imp.includes(
                                    "private residential purchase",
                                    ""
                                  )
                                    ? "Private Residential Purchase"
                                    : imp.includes("motor vehicle loan", "")
                                    ? "Motor Vehicle Loan"
                                    : imp.includes("hdb loan", "")
                                    ? "HDB Loan"
                                    : ""}
                                </div>
                                <div>
                                  {imp
                                    .replace("private residential purchase", "")
                                    .replace("motor vehicle loan", "")
                                    .replace("hdb loan", "")
                                    .trim()
                                    .split(" ")[0]
                                    .toUpperCase()}
                                </div>
                                <div>
                                  {parseFloat(
                                    imp
                                      .split("   ")
                                      [imp.split("   ").length - 4].replace(
                                        /,/g,
                                        ""
                                      )
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </div>
                                <div>
                                  {JSON.parse(bor.credit_report_data)
                                    ?.CBSImpLoanSupplementaryInstallment?.[
                                    index10
                                  ]
                                    ? (
                                        parseFloat(
                                          JSON.parse(bor.credit_report_data)
                                            ?.CBSImpLoanSupplementaryInstallment[
                                              index10
                                            ].split("   ")
                                            [
                                              JSON.parse(
                                                bor.credit_report_data
                                              )?.CBSImpLoanSupplementaryInstallment[
                                                index10
                                              ].split("   ").length - 4
                                            ]?.replace(/,/g, "")
                                        ) +
                                        parseFloat(
                                          JSON.parse(bor.credit_report_data)
                                            ?.CBSImpLoanSupplementaryInstallment[
                                              index10
                                            ].split("   ")
                                            [
                                              JSON.parse(
                                                bor.credit_report_data
                                              )?.CBSImpLoanSupplementaryInstallment[
                                                index10
                                              ].split("   ").length - 3
                                            ]?.replace(/,/g, "")
                                        )
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })
                                    : null}
                                </div>
                              </div>
                            ))
                        : "No outstanding loans"
                      : "No CBS Report found"}
                  </div>
                  <div style={{ width: "12%" }}>
                    <div>
                      {bor?.singpass_data?.person?.cpfhousingwithdrawal
                        ?.withdrawaldetails?.length > 0
                        ? `${bor?.singpass_data?.person?.cpfhousingwithdrawal?.withdrawaldetails?.length} private property in s.pass`
                        : "N/A"}
                    </div>
                    <div>
                      {searchingArray
                        .filter((ite) => ite.index === index)?.[0]
                        ?.address.map((add, index) => (
                          <div>
                            {index + 1 + ") " + add?.address?.street?.value}
                            {/* a+
                              ", " +
                              add?.address?.postal?.value} */}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div style={{ width: "12%" }}>
                    <div>
                      {searchingArray.filter((ite) => ite.index === index)?.[0]
                        ?.searched === true
                        ? searchingArray
                            .filter((ite) => ite.index === index)?.[0]
                            ?.possibleResults.map((add, index) => (
                              <div key={index}>
                                {index +
                                  1 +
                                  ") " +
                                  (add
                                    ? add.length + " possible results"
                                    : null)}
                              </div>
                            ))
                        : searchingArray.filter(
                            (ite) => ite.index === index
                          )?.[0]?.searched === false
                        ? "Searching..."
                        : "N/A"}
                    </div>
                  </div>
                  <div style={{ width: "12%" }}>
                    {searchingArray.filter((ite) => ite.index === index)?.[0]
                      ?.searched === true
                      ? bestResults(
                          searchingArray.filter(
                            (ite) => ite.index === index
                          )?.[0]
                        )
                      : "N/A"}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Valuer;
